import { getRepositoryEndpoint, createClient, Client } from "@prismicio/client";
import { PrismicDocument } from "@prismicio/types";

function newPrismicClient(): Client {
    const endpoint = getRepositoryEndpoint("courtsite");
    return createClient(endpoint);
}

export async function getKonsolHomeDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("konsol_home", {});
}

export async function getKonsolFooterDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("konsol_footer", {});
}

export async function getKonsolPricingDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("konsol_pricing", {});
}

export async function getKonsolReferralDoc(): Promise<PrismicDocument> {
    const client = newPrismicClient();
    return await client.getSingle("konsol_referral", {});
}
