import { useState, useEffect } from "react";
import { PrismicDocument } from "@prismicio/types";
import { RichText, RichTextBlock } from "prismic-reactjs";
import { getKonsolFooterDoc } from "lib/prismic";

type LinkItemBlock = {
    name: {
        text: string;
    }[];
    path: {
        text: string;
    }[];
};
type Section = {
    title: string;
    links: {
        name: string;
        path: string;
    }[];
};
type Social = {
    img: string;
    alt: string;
    path: string;
};
type Footer = {
    copyright: string;
    sections: Section[];
    social: Social[];
};

export const useFooter = (): Footer => {
    const [documentFooter, setDocumentFooter] = useState<PrismicDocument>();
    useEffect(() => {
        getPrismicFooter().then((res) => setDocumentFooter(res));
    }, []);

    if (!documentFooter) {
        return {
            copyright: "",
            sections: [],
            social: [],
        };
    }
    const sections: Section[] = documentFooter.data.body.map(
        (f: {
            primary: {
                title: RichTextBlock[];
            };
            items: LinkItemBlock[];
        }) => ({
            title: RichText.asText(f.primary.title),
            links: f.items
                .map((fi) => {
                    const link = fi.path[0]?.text ?? "";
                    const isPartnerCentre = link.includes("/centre");
                    const partnerCentreLink = `https://www.courtsite.my${link}`;
                    return {
                        name: fi.name[0]?.text,
                        path: isPartnerCentre ? partnerCentreLink : link,
                    };
                })
                .filter(
                    (i): i is { name: string; path: string } =>
                        i.name !== undefined,
                ),
        }),
    );
    const social: Social[] = documentFooter.data.social.map(
        (s: {
            img: {
                url: string;
                alt: string;
            };
            path: {
                text: string;
            }[];
        }) => ({
            img: s.img.url,
            alt: s.img.alt,
            path: s.path[0]?.text ?? "",
        }),
    );

    return {
        copyright: RichText.asText(documentFooter.data.copyright),
        sections: sections,
        social: social,
    };
};

export const getPrismicFooter = async (): Promise<PrismicDocument> => {
    return await getKonsolFooterDoc();
};
